import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckCircle, faTimesCircle, faFileArchive, faHeart } from '@fortawesome/free-regular-svg-icons'
import { faBolt, faMusic, faDownload, faCog, faSearch, faTimes, faSignOutAlt, faSync, faLightbulb, faCircleInfo, faGlobe, faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faCheckCircle,
  faTimesCircle,
  faFileArchive,
  faHeart,
  faBolt,
  faMusic,
  faDownload,
  faCog,
  faSearch,
  faTimes,
  faSignOutAlt,
  faSync,
  faLightbulb,
  faCircleInfo,
  faGlobe,
  faCheck
)
